<template>
<div class="swiper-ke">
  <!--基础存放容器-->
    
  <div class="swiper-container" id="shiying">
    <!-- 需要进行轮播的部分 -->
  
    <div class="swiper-wrapper">
      <!-- 每个节点 -->
      <!-- <div class="swiper-slide">
        <img src="@/assets/images/dingxie.jpg" alt="钉鞋图片" />
      </div>
      <div class="swiper-slide">
        <img src="@/assets/images/3.0.jpg" alt="图片1" />
      </div><div class="swiper-slide">
        <img src="@/assets/images/3.0orange.jpg" alt="图片2" />
      </div><div class="swiper-slide">
        <img src="@/assets/images/3.0white.jpg" alt="图片3" />
      </div><div class="swiper-slide">
        <img src="@/assets/images/2.5.jpg" alt="图片4" />
      </div>
      <div class="swiper-slide">
        <img src="@/assets/images/white.jpg" alt="图片5" />
      </div>
      <div class="swiper-slide">
        <img src="@/assets/images/orange.jpg" alt="图片6" />
      </div> -->
      <div class="swiper-slide">
        <img src="@/assets/images/wushanghaibao.jpg" alt="图片1" />
      </div><div class="swiper-slide">
        <img src="@/assets/images/tuoxiehaibao.jpg" alt="图片1" />
      </div>
      <div class="swiper-slide">
        <img src="@/assets/images/shouyejingsuxie.jpg" alt="图片1" />
      </div>
      <div class="swiper-slide">
        <img src="@/assets/images/shouyedingxie.jpg" alt="图片1" />
      </div>
      <div class="swiper-slide">
        <img src="@/assets/images/shouyexunlianxie.jpg" alt="图片1" />
      </div>
      <div class="swiper-slide">
        <img src="@/assets/images/shouye2.5.jpg" alt="图片1" />
      </div>
      
      <div class="swiper-slide">
        <img src="@/assets/images/shouyeheikuzi.jpg" alt="图片1" />
      </div>
      
    </div>

    <!--如果需要使用分页器-->
    <div class="swiper-pagination swiper-pagination-white"></div>

    <!-- 如果需要使用前进后退按钮 -->
    <div class="swiper-button-prev swiper-button-white"></div>
    <div class="swiper-button-next swiper-button-white"></div>
  </div>
</div>
</template>



<script>
import Swiper from "swiper"
import "swiper/dist/css/swiper.css"
export default {
    name: "PaiSwiper",
data() {
    return {};
  },
  mounted() {
    this.initSwiper();
  },
  methods: {
    initSwiper() {
      new Swiper(".swiper-container", {
        //设置轮播的循环方式
        loop: true,

        //设置自动播放间隔时间
        autoplay: 2000,

        // 轮播效果,默认为平滑轮播
        effect: "slide",

        //分页器
        pagination: ".swiper-pagination",

        //前进后退按钮
        prevButton: ".swiper-button-prev",
        nextButton: ".swiper-button-next",

        // 用户中断轮播后续播
        autoplayDisableOnInteraction: false,
      });
    },
  },
}
</script>

<style scoped>
/* .swiper-ke{
  width: 100%;
  background-image: url(../assets/images/first-bgi.jpg);
  background-repeat: no-repeat;
  background-position:center center;
} */

.swiper-container {
  /* width: 1690px; */
  min-width: 1200px;
  width:100%;
   /* width: 1200px; */
  margin: 0 auto;
  height: 800px;
  /* height: 754px;  */
  /* margin-top: 40px; */
}
.swiper-slide {
  display: flex;
  background-color: #000;
  justify-content: center;
  align-items: center;

}
.swiper-slide img {
  /* width: 1200px;
   */
   width: 100%;
  height: 100%;
  object-fit: cover;
}


/* .swiper-button-prev {
  margin-left:245px;
}

.swiper-button-next {
  margin-right: 245px;
} */
</style>